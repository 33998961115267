.cfa-prioridad-container {
  width: 100%;
  padding: 20px;
}

.cfa-prioridad-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cfa-prioridad-header h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #333;
}

.cfa-button-guardar {
  background-color: #8A4BFF;
  color: #FFF;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cfa-button-guardar:hover {
  background-color: #6A3AC4;
}

.cfa-grupos-envio {
  display: inline;
}

.cfa-grupo-envio {
  background-color: #FFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cfa-grupo-envio h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
}

.cfa-grupo-table {
  width: 100%;
  border-collapse: collapse;
}

.cfa-grupo-table thead th {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #666;
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 2px solid #EEE;
}

.cfa-grupo-table tbody tr {
  border-bottom: 1px solid #EEE;
}

.cfa-grupo-table tbody tr:last-child {
  border-bottom: none;
}

.cfa-grupo-table tbody td {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #333;
  padding: 10px 0;
}

.cfa-custom-row {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
}

.cfa-custom-row td {
  padding: 12px;
}

.cfa-custom-row td:first-child {
  text-align: center;
}

.css-1vo6kym-MuiButtonBase-root-MuiIconButton-root{
  display: none !important;
}

.grupo-table{
  height: 30rem;
}