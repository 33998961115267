.cfc_etiq_color {
  border-radius: 5px;
  background: var(--colors-amber-100, #00868b2e);
  color: var(--secondary-900, #00868b) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 5px 10px;
}

.cfc_switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  align-items: center;
}

.cfc_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.cfc_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 25px;
}

.cfc_slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .cfc_slider {
  background-color: rgb(101, 41, 161);
}

input:checked + .cfc_slider:before {
  transform: translateX(25px);
}

.cfc_checkbox:checked + label::before {
  background-color: #6529a1;
  border-color: #6529a1;
}

.cfc_slider:after {
  content: 'OFF';
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 52%;
  left: 75%;
  font-size: 10px;
}

input:checked + .cfc_slider:after {
  content: 'ON';
  left: 25%;
}
