:root {
  --bdw-green: #caf7e3;
}

.input-group-bdw {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}

.login-content {
  min-height: 100vh;
  text-align: center;
  background-color: #eee;
  position: relative;
}
.login-content:before {
  content: '';
  height: 100vh;
  width: 1px;
}
.nk-block {
  max-width: 500px;
  padding: 20px 0;
  width: 80%;
  position: relative;
  margin: 0 auto;
}

.nk-navigation a,
.nk-navigation a i {
  vertical-align: top;
  display: inline-block;
}
.nk-block:not(.toggled) {
  display: none;
}
.nk-block.toggled {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 10;
}

.nk-block:not(.lc-block-alt) .nk-form {
  padding: 40px;
}

.bdw-logo {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 10px;
}

.bdw-login-title {
  font-weight: 500;
  margin-bottom: 20px;
}

.nk-block:not(.lc-block-alt) .btn-login {
  top: 56%;
  margin-top: -50px;
  right: -25px;
}
.nk-form {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.nk-navigation {
  margin-top: 15px;
}
.nk-navigation a,
.nk-navigation a span {
  transition: width;
  transition-duration: 0.2s;
}
.nk-navigation a {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  margin: 0 1px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  white-space: nowrap;
  text-align: left;
}
.nk-navigation a i {
  width: 30px;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
}
.nk-lg-ic .notika-plus-symbol {
  font-size: 12px;
}
.nk-navigation a span {
  width: 0;
  overflow: hidden;
  display: inline-block;
  line-height: 29px;
  margin-left: -3px;
}
.nk-navigation a:hover span {
  width: 100%;
}
.nk-navigation a[data-ma-block='#l-register']:hover {
  width: 95px;
}
.nk-navigation a[data-ma-block='#l-forget-password']:hover {
  width: 147px;
}
.nk-navigation a[data-ma-block='#l-login']:hover {
  width: 85px;
}
.lc-block-alt .nk-form {
  padding: 70px 35px 60px;
}
.lc-block-alt .btn-login {
  bottom: 0;
  left: 50%;
  margin-left: -25px;
}
.lcb-user {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #fff;
  position: absolute;
  top: -35px;
  left: 50%;
  margin-left: -50px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.18);
}
.input-group-addon.nk-ic-st-pro {
  border: 0px solid #ccc;
  background: #fff;
  font-size: 25px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.nk-block .fm-checkbox {
  margin: 15px 0 0 41px;
  text-align: left;
}
.btn-float {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 46px !important;
}
.btn-float:not(.m-btn) {
  position: absolute !important;
}
#btn-login-token.btn-float:not(.m-btn) {
  position: unset !important;
}

.btn-token-verde {
  width: 10% !important;
  border-radius: 50px !important;
  margin: 0px !important;
  padding: 1px !important;
}

.btn-login.btn-float i {
  font-size: 20px;
}
.btn-float:not(.bgm-white):not(.bgm-gray) > i {
  color: #fff;
}

.btn-login {
  background-color: #eee !important;
  border-color: #eee !important;
}

.btn-login.btn-success,
.images-cropper-pro .btn,
.images-action-pro .btn,
.notika-todo-btn .btn,
.btn.notika-add-todo,
.btn.notika-chat-btn {
  background-color: var(--bdw-green);
  border-color: var(--bdw-green);
  border: 0px solid #ccc;
  outline: none;
  box-shadow: none;
}
.btn-login.btn-success:hover,
.btn-login.btn-success:focus,
.btn-login.btn-success:active,
.images-cropper-pro .btn:hover,
.images-cropper-pro .btn:focus,
.images-cropper-pro .btn:active,
.images-action-pro .btn:hover,
.images-action-pro .btn:focus,
.images-action-pro .btn:active,
.notika-todo-btn .btn:hover,
.notika-todo-btn .btn:focus,
.notika-todo-btn .btn:active,
.btn.notika-add-todo:hover,
.btn.notika-add-todo:focus,
.btn.notika-add-todo:active,
.btn.notika-chat-btn:hover,
.btn.notika-chat-btn:focus,
.btn.notika-chat-btn:active {
  background-color: var(--bdw-green);
  border-color: var(--bdw-green);
  border: 0px solid #ccc;
  outline: none;
  box-shadow: none;
}

.nk-block,
.login-content:before,
.error-page-wrap,
.error-page-area:before {
  vertical-align: middle;
  display: inline-block;
}
.nk-form p {
  font-size: 14px;
  color: #333;
  line-height: 24px;
}

/* input */

.nk-int-st input[type='text'],
.nk-int-st textarea,
.nk-int-st [type='number'],
.nk-int-st [type='password'] {
  box-shadow: none;
  border-top: 0px solid #ccc;
  border-left: 0px solid #ccc;
  border-right: 0px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0px;
  resize: none;
  border-radius: 0px;
}
.nk-int-st textarea:active,
.nk-int-st textarea:focus,
.nk-int-st textarea:hover,
.nk-int-st [type='password']:focus,
.nk-int-st [type='password']:active {
  box-shadow: none;
}
.nk-int-st {
  position: relative;
  vertical-align: top;
}

.nk-int-st:not(.disabled):after,
.nk-int-st:not(.readonly):after {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  content: '';
  transform: scale(0);
  transition: all;
  transition-duration: 0.3s;
}
.nk-int-st:not(.form-group) {
  display: inline-block;
  width: 100%;
  margin-top: 8px;
}
.nk-int-st.nk-toggled:after {
  transform: scale(1);
}

/* ICON */

.bdw-icon-arrow {
  font-size: 25px;
  margin-bottom: 7px;
  color: #333;
}

.bdw-icon-arrow:hover {
  color: #ffffff;
}
