.custom-day-picker {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  /* position: absolute; */
  z-index: 1000;
}

.custom-day-picker .rdp-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.custom-day-picker .rdp-caption select {
  margin: 0 5px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.custom-day-picker .rdp-day {
  color: #6529A1;
}

.custom-day-picker .rdp-day:hover:not(.rdp-day_outside) {
  background-color: #f0e6ff;
}

.custom-day-picker .my-selected:not(.rdp-day_outside) {
  background-color: #6529A1;
  color: #fff;
}

.custom-day-picker .my-selected:hover:not(.rdp-day_outside) {
  background-color: #530d91;
}

.custom-day-picker .rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.custom-actions {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #fff;
}

.custom-actions button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.custom-actions .clear-btn {
  background-color: #000;
  color: #fff;
}

.custom-actions .save-btn {
  background-color: #6529A1;
  color: #fff;
}

.calendar-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 19px;
  transform: translateY(-50%);
}
