/*.rule-config {
  margin-top: 30px;
}*/
.rule-config:hover {
  color: #dfdfdf;
}

.btn-primary {
  --bs-btn-border-color: #b3c3bd !important;
}

.rule-config__wrapper {
  padding: 20px;
  background: #fff;
}

.rule-config__title-container {
  display: flex;
  margin-bottom: 30px;
}

.align-right{
  float: right;
  text-align: right;
}

.align-left{
  text-align: left;
}


.panel {
  border-bottom: 1px solid #dfdfdf;
    margin: 0px 13px;
    padding: 20px;
}

.rule-config__title-icon {
  margin-right: 10px;
  font-size: 20px;
  line-height: 20px;
  color: var(--bdw-green);
}

.rule-config__title-text {
  font-size: 20px;
  font-weight: 500;
}

.rule-config__endpoint-selector-container {
  display: flex;
}

.rule-config__endpoint-selector-text, .vtexskuconsult__text {
  text-align: left;
  margin: 0px 15px 0 0;
  padding: 0 0 0 45px;
}

.vtexskuconsult__text_modal {
  text-align: right;
  margin: 0px 15px 0 0;
  padding: 0 0 0 45px;
}

.vtexskuconsult__text_modal_g {
  text-align: left;  
  padding: 0 0 0 45px;
}

.rule-config-subComponents__container {
  margin-top: 30px;
}

.rule-config select,
.vtexskuconsult__input,
.rule-config input {
  background: #f5f5f5;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  height: 35px;
}
.rule-config input {
  width: 60%;
}
.rule-config button {
  background: #262324;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
  margin: 10px 8px;
  width: 175px;
  height: 40px;
}

.ppal_button {
  background: var(--bdw-green) !important;
}
.rule-config__preview-button {
  justify-content: flex-end;
  display: flex;
}
.rule-config__wrapper h6 {
  font-size: 15px;
  font-weight: 500;
}

.button_container_rule {
  display: flex;
  width: 60%;
  justify-content: left;
  flex-wrap: wrap;
  margin-left: 253px;
}

.select-categos {
  width: 100%;
  height: 45px;
  padding-left: 10px;
  margin-left: -10px;
}

.select2-categos {
  width: 100%;
  height: 45px;  
  margin-left: -10px;
}

.select2-categos-sw {  
  height: 45px;  
  margin-left: -19px;
}

.mf-22{
  margin-left: -22px;
}

.css-13cymwt-control{
  background-color: hsl(0deg 14.29% 98.63%) !important;
}

.match-group-1 {
  margin: 5px 0px 0 -18px;
}

.input-stk-min {
  width: 68%;

  margin-left: -12px;
}

.match-group-2 {
  margin: 5px 0px 0 15px;
}

/* NOTEBOOK */
@media (max-width: 1400px) {
  .button_container_rule {
    display: flex;
    width: 75%;
    justify-content: left;
    flex-wrap: wrap;
    margin-left: 312px;
    margin-top: 10px;
  }

  .subscription__endpoint-selector-text,
  .vtexskuconsult__text {
    margin: 10px 2rem 0 10px !important;
    vertical-align: middle;
    margin: auto 0;
  }

  .rule-config__endpoint-selector-text,
  .vtexskuconsult__text {
    /*margin: 0px 15px 0 10px !important; */
    vertical-align: middle;
    margin: auto 0;
  }
}

.ancho-alto-select {
  height: 37px;
  width: 33%;
  background: #fcfbfb;
  border: 1px solid #dfdfdf;
}

/* toast alert */

h2.swal2-title {
  background-color: #fff !important;  
  padding-bottom: 15px !important;
  border-radius: 4px 4px 0 0 !important;
  box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 10%) !important;
}
.swal2-popup.swal2-toast .swal2-close{
  grid-row: 1 !important;
  grid-column: 3/1 !important;
}
.swal2-popup.swal2-toast .swal2-icon{
  margin: 0 0.5em 0 13px !important;
  grid-row: 1/2 !important;
  grid-column: 2 !important; 
}

.row.a-center{
  align-items: center;
}

.input_radBton{
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 8px;
  height: 35px;
  width: 250px;
}

.input_form_radBton{
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 8px;
  height: 35px;
  width: 82%;
  background: #fcfbfb;
}

.ml_10{
  margin-left: -3%;
}

.form-check-input:checked {
  background-color: #6529A1;
  border-color: #6529A1;
}

.disabled-input-modal{
  pointer-events:none;
  cursor: not-allowed;
  background-color: rgb(229, 229, 229) !important;
}

.hiden-rule{
  display: none;
}