.automation {
  margin-top: 20px;
}
.automation__wrapper {
  padding: 20px;
  background: #fff;
}

.box_sha{
  box-shadow: 0px 5px 8px -5px rgb(0 0 0 / 20%);
}

.automation__title-container {
  display: flex;

}

.automation__title-icon {
  margin-right: 10px;
  font-size: 25px;
  line-height: 20px;
  color: var(--bdw-green);
}

.automation__title-text {
  font-size: 20px;
  margin-top: 4px;
}

.automation__endpoint-selector-container {
  display: flex;
  align-items: center;
}
.col-lg-12.col-md-12.col-sm-12.col-xs-12.automation__endpoint-selector-container {
  margin: 10px 0 20px;
}
.automation__buttons-container {
  display: flex;
  justify-content: center;
}

.automation__endpoint-selector-text,
.vtexskuconsult__text {
  text-align: center;
  vertical-align: middle;
  margin: 10px 1rem 0 0;
}
.automation__endpoint-selector-container input.input {
  border-radius: 0.375rem;
}

.automation__endpoint-selector-textArea {
  text-align: center;
  vertical-align: middle;
  margin: 0 2rem 0 0;
  padding: 0 0 0 20px;
}

.automation__input-w100 {
  width: 100% !important;
}

.automation-subComponents__container {
  margin-top: 30px;
}

.automation select,
.vtexskuconsult__input,
.automation input {
  background: #f5f5f5;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  height: 35px;
}
.automation input {
  width: 50%;
}
.automation button {
  background: #F29718;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  margin: 10px 8px;
  width: 175px;
  height: 40px;
}
.automation__title-icon svg {
  fill: #fff !important;
}
.button_container {
  display: flex;
  width: 400px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  left: 14.5%;
}
.ppal_button {
  background: var(--bdw-green) !important;
}
.automation__preview-button {
  justify-content: flex-end;
  display: flex;
}
/*.automation__wrapper h6 {
  font-size: 15px;
    font-weight: 500;
}*/
.automation__p {
  font-size: 12px;
  font-weight: 600;
  margin-top: 1rem;
  margin-left: 5rem;
}

input::-webkit-file-upload-button {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  background: var(--bdw-green) !important;
  cursor: pointer;
  color: #fff;
  content: 'hola' !important;
}

.label-warning {
  background-color: #f0ad4e;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.border-shadow {
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
}

.btn-reload {
  float: right;
  margin: -10px 0 5px 0;
}

.container-file{
  gap: 10px;
  align-items: center;
}
.fs-14{
  font-size: 14px;
}