.header-top-area {
  background: #fff;
}

.head_height {
  height: 87px;
  justify-content: center !important;
}

.avatar-button {
  background: #262324;
  border-style: none;
  width: 60px;
}

p.text_example_csv_1 {
  background: #f8f8f8;
  font-style: italic;
  font-size: 10px;
  color: #797878;
  font-family: unset;
  border: 1px solid #dfdfdf;
  padding: 4px 5px 1px 10px;
  border-left: 10px solid #cecccc;
}

.logout-button {
  background: rgba(102, 204, 153, 1);
  z-index: 90;
  width: 180px;
  margin: 5px 5px 0px 0px;
}

.logout-button-ul {
  margin: 20px;
}

.logout-button-button {
  background: rgba(102, 204, 153, 1);
  border-style: none;
}

.logout-button-button:hover {
  background-color: #fff;
}
.logo-area {
  text-align: center;
}
.logo-img {
  height: 87px;
  padding: 10px;
}

.avatar {
  margin-top: 0.5rem;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.form-bdw {
  padding: 0.25rem 1rem;
  font-size: 0.85rem;
}

.lang {
  display: inline-flex;
}

.es-icon {
  margin-right: 5px;
  width: 25px;
}

.us-icon {
  margin-left: 5px;
  width: 25px;
}

.user-image-head {
  /*max-width: 17% !important;*/
  width: 36px !important;
  height: 36px !important;
}
.rounded-circle {
  border: 2px solid #fff;
}
.rounded-circle-black {
  border: 2px solid #000 !important;
}

/*.check {
  position: relative;
  width: 50px;
}

.check:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 25px;
  background: #333;
  border-radius: 25px;
}

.check:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 25px;
  transition: 0.25s;
  border: 2px solid #333;
  box-sizing: border-box;
}

.check:checked:after {
  left: 25px;
  border: 2px solid #00a1ff;
}

.check:checked:before {
  background: #00a1ff;
}*/

.btn-lang {
  display: contents;
}

@media (max-width: 992px) {
  .header-top-area {
    position: unset;
    height: 20vh;
  }

  .container-fluid {
    width: 300px;
  }

  .col-xs-12 {
    width: 100%;
  }

  .logo-area {
    text-align: center;
  }

  .avatar-container {
    display: none;
  }

  .form-bdw {
    width: 7.875rem;
    font-size: 13px;
  }

  .lang {
    display: none;
  }
}

/*************/

.switch_mode {
  display: inline-block;
  height: 25px;
  position: relative;
  width: 65px;
}

.switch_mode input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-bckg-on {
  background-color: #6529a1;
}
.slider-bckg-off {
  background-color: #bbb;
}
.slider-mode {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: #ccc;*/
  transition: 0.4s;
}

input:checked + .slider-mode {
  background-color: #6529a1;
}

input:focus + .slider-mode {
  box-shadow: 0 0 1px #6529a1;
}

input:checked + .slider-mode:before {
  transform: translateX(40px);
}

.slider-mode.round {
  border-radius: 30px;
}

.slider-mode.round:before {
  border-radius: 30px;
}

small.switch_m {
  padding-left: 30px;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
}
.slider-bckg-on small.switch_m {
  padding-left: 13px;
}
small.switch_m_mshop {
  padding-left: 5px;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
}
button#dropdown-basic {
  font-size: 14px;
  padding: 5px;
}
