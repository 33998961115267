.nav-tabs.bdw-menu-wrap {
  border-bottom: 0 solid #ddd;
  justify-content: space-between;
  -moz-column-gap: 40px;
  column-gap: 40px;
}

.nav-tabs.bdw-menu-wrap.bdw-submenu {
  justify-content: center;
}

.lang {
  display: inline-flex;
  justify-content: space-around;
}

.switch_mode {
  display: inline-block;
  height: 25px;
  position: relative;
  width: 80px;
  margin: 0 30px;
}

input:checked + .slider-mode {
  background-color: #6529a1;
  border-radius: 30px;
}

.slider-mode:before {
  background-color: #fff;
  bottom: 2px;
  content: '';
  height: 21px;
  left: 2px;
  position: absolute;
  transition: 0.4s;
  width: 21px;
  border-radius: 50px !important;
}

.nav-tabs.bdw-menu-wrap > li.active > a,
.nav-tabs.bdw-menu-wrap > li.active > a:focus,
.nav-tabs.bdw-menu-wrap > li.active > a:hover {
  border: 0px solid #ddd;
  border: none;
}

.notika-tab-menu-bg {
  position: relative;
}

.notika-tab-menu-bg:before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background: #fff;
  content: '';
  z-index: 9;
  height: 100%;
}

.custom-menu-content ul.notika-main-menu-dropdown {
  position: relative;
  z-index: 99;
}

.main-menu-dashboard .main-menu-area {
  background-color: #242424;
}

.main-menu-area {
  background-color: #363636;
  height: 45px;
  display: flex;
  align-items: center;
}

ul.bdw-menu-wrap li a {
  border: none;
  font-size: 13px;
  color: #333;
  padding: 10px;
}

.custom-menu-content ul.notika-main-menu-dropdown li {
  display: inline-block;
}

.custom-menu-content ul.notika-main-menu-dropdown li a {
  padding: 20px 20px;
  display: block;
  font-size: 13px;
  color: #333;
}

.custom-menu-content ul.notika-main-menu-dropdown li ul.layout-more-dp {
  position: absolute;
  z-index: 999;
  width: 200px;
  left: -20px;
  border: none;
  border-radius: 0px;
}

.custom-menu-content ul.notika-main-menu-dropdown li ul.layout-more-dp li a {
  font-size: 13px;
  color: #333;
  padding: 10px 20px;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important;
}

.mg-t-80-b-40 {
  margin: 102px 0px 40px 0px;
}

.mean-container .mean-nav ul li a {
  color: #333;
}

.mean-container .mean-nav ul li a:hover {
  color: #00c292;
}

.box-layout .header-top-area {
  width: 1200px;
}

/* Icon */

.bdw-menu-icon {
  font-size: 22px;
  margin-bottom: 0.6rem;
  position: relative;
  top: 2px;
  display: none;
}

.bdw-menu-mobile {
  display: none;
}

@media (max-width: 992px) {
  .bdw-menu-mobile {
    display: flex;
    justify-content: space-around;
  }

  .bdw-menu-mobile-title {
    font-size: 1.75rem;
    color: #fff;
  }

  .bdw-menu-mobile-icon {
    font-size: 1.75rem;
    color: #fff;
    background-color: #262626;
    border: none;
  }

  .mobile-menu-area {
    display: flex;
    background-color: #262626;
    margin-top: 0px;
  }

  .bdw-menu-icon {
    display: none;
  }

  .bdw-menu-wrap {
    flex-direction: column;
    align-items: center;
    background-color: #fff;
  }

  .customLink-container {
    margin: 13px;
    margin-right: 30px !important;
  }
}

.customLink-container {
  margin: 0 5px;
}

.video-home {
  padding: 5px;
  /*background: #262324;
    border-radius: 15px;*/
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
  /*box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);*/
}

.iframe-video {
  border: 20px solid #fff;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
}

.hide {
  display: none !important;
}
#root > div.main-menu-area.pd-tb-15.mobile-menu-area > div > div > div > ul {
  justify-content: center !important;
}
#root
  > div.main-menu-area.pd-tb-15.mobile-menu-area
  > div
  > div
  > div
  > ul
  > li {
  margin: 0 25px;
}
