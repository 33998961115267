h5.card-title {
  font-size: 20px !important;
}
h6.card-subtitle {
  font-size: 14px !important;
  margin: 15px auto 5px !important;
  font-family: 'Raleway';
}
p.card-text {
  font-size: 13px !important;
  margin: 15px auto !important;
  font-family: 'Raleway';
}

.slick-prev:before,
.slick-next:before {
  color:var(--bdw-green) !important;
}
.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
  object-fit: cover;
  object-position: 100% 0;
  height: 260px;
}
.card-img-top{
  border-radius: 5px 0 0 5px
}

.slick-prev, .slick-next{
  color:var(--bdw-green) !important;
}
.slick-dots li button:before{
  font-size: 13px !important;
}