.modal-header h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.day-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
}
