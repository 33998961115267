.switch-container {
  position: relative;
  display: inline-block;
}

.custom-switch .form-check-input {
  width: 50px;
  height: 25px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #ccc;
  background-repeat: no-repeat !important;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

.custom-switch .form-check-input:checked {
  background-color: #6529a1;
}

.custom-switch .form-check-input:before {
  content: 'OFF';
  position: absolute;
  left: 23px;
  font-family: 'Poppins';
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 12px;
}

.custom-switch .form-check-input:checked:before {
  content: 'ON';
  left: 7px;
  font-family: 'Poppins';
}

.regla-titulo {
  padding-left: 11.3rem;
}

.regla-nombre {
  display: flex;
  align-items: center;
}

.regla-nombre-span {
  margin: 0 10px 0 0 !important;
  font-weight: bold !important;
  font-size: 32px !important;
}

.regla-nombre-span-2 {
  font-size: 32px !important;
}

.regla-cliente {
  margin: 10px 0 10px 0;
  font-size: 32px;
  color: #6529a1;
  font-family: 'Poppins';
  font-weight: 600;
}

.cfa-utm {
  margin-bottom: 30px;
}

.form-grupo {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}

.form-grupo label {
  flex-basis: 20%;
}

.form-grupo input,
.cfa-grupo select,
.cfa-grupo textarea {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cfa-formulario {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.cfa-formulario label{
  color: var(--greys-grey-800, #666);
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.cfa-formulario h1{
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px !important;
}

.cfa-campana {
  margin-bottom: 30px;
}

.label-subtitulo {
  font-family: 'Poppins';
  font-style: italic;
  color: grey;
}

.dias-semana {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Poppins';
}

.dias-semana button {
  flex: 1;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.dias-semana button:hover {
  background-color: #00868b14;
  color: #00868b;
  font-weight: bold;
}

.dias-semana button.activo {
  background-color: #00868b14;
  color: #00868b;
  font-weight: bold;
}

.intervalo {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 10px;
}

.intervalo label,
.intervalo span {
  white-space: nowrap;
}

.intervalo input {
  width: 7rem;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.plantilla,
.url-plantilla,
.a-form-input {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.plantilla label,
.url-plantilla label,
.a-form-input label {
  white-space: nowrap;
}

.plantilla select,
.url-plantilla input,
.a-form-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cfa-configuracion {
  margin-bottom: 30px;
}

.configuracion-grupo {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 10px;
}

.configuracion-grupo label {
  white-space: nowrap;
}

.configuracion-grupo input,
.configuracion-grupo select {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.configuracion-grupo button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.configurar-regla {
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 186px;
  height: 34px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #fff;
  background-color: #00868b;
  text-transform: capitalize;
  margin-block: 20px;
}

.configurar-regla:hover {
  background: #00868b;
}

.replicar-datos {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #6529a1;
  background: transparent;
  margin-top: 2rem;
  padding: 5px;
  color: #6529a1;
  text-transform: uppercase;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
}

.replicar-datos:hover {
  background: transparent;
}

.email-form-group {
  display: flex;
  align-items: center;
  gap: 37px;
  justify-content: center;
  margin-top: 15px;
}

.email-button-enviar {
  width: 254px;
  border: 1px solid #000000;
  background: #00868b;
  color: #fff;
  text-transform: uppercase;
}

.email-button-preview {
  width: 254px;
  border: 1px solid #00868b;
  background: transparent;
  color: #00868b;
  text-transform: uppercase;
}

.header-url {
  flex-basis: 39% !important;
}

.header-input {
  flex-basis: 80%;
}

.header-button-select {
  width: 34%;
  border: 1px solid #00868b;
  color: #00868b;
  background: transparent;
  width: 43%;
  height: 38px;
  padding: unset;
}

.header-button-upload {
  width: 18%;
  border: 1px solid #00868b;
  color: #00868b;
  background: transparent;
  width: 43%;
  height: 38px;
  padding: unset;
}

.header-button-select:hover,
.header-button-upload:hover {
  border: 1px solid #00868b;
  color: #00868b;
  background: transparent;
}

.guardar-form-group {
  display: flex;
  align-items: center;
  gap: 37px;
  justify-content: center;
  margin-top: 15px;
}

.guardar-button,
.guardar-button:hover {
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 186px;
  height: 34px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #fff;
  background-color: #00868b;
  text-transform: capitalize;
  margin-block: 20px;
}

.volver-button,
.volver-button:hover {
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 186px;
  height: 34px;
  border-radius: 3px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #fff;
  background-color: #00868b;
  text-transform: capitalize;
  margin-block: 20px;
}

.section-automation {
  margin-bottom: 30px;
}

.label-automation {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.automation-edit input,
.automation-edit select {
  margin-bottom: 15px;
  color: var(--greys-grey-800, #666);
  background: #f4f4f4;
  border-radius: 5px;
  font-weight: 400;
  outline: none;
  width: 620px;
  height: 30px;
  padding: 2px 10px;
  border-right: 8px solid transparent;
  border: 1px solid #ced4da;
}

.automation-textarea{
  margin-bottom: 15px;
  color: var(--greys-grey-800, #666);
  background: #f4f4f4;
  border-radius: 5px;
  font-weight: 400;
  outline: none;
  width: 620px;
  height: 80px;
  padding: 2px 10px;
  border-right: 8px solid transparent;
  border: 1px solid #ced4da;
}

.email-form-group{
  margin-left: 18%;
}

.email-form-group button {
  width: 50%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}



.button-automation {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

/* .button-automation:hover {
  background-color: #0056b3;
} */

.calcular-button{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 2px solid;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: #00868B;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
}
.cfa-botones {
  display: flex;
  justify-content: space-between;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 30rem;
}

.popup-title {
  font-size: 20px;
  font-weight: 600;
}

.days-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.day-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}

input[type='checkbox'] {
  margin-right: 10px;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-play {
  height: 3rem;
  font-size: 19px;
  width: 285px;
  background: #6529a1;
}

.button-cancel {
  height: 3rem;
  font-size: 19px;
  width: 137px;
  background: #444444;
}

.select-automation{
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.automation-edit-loading{
  position: relative;
  left: 33rem;
  top: 1rem;
  width: fit-content;
}