.related_categories__title-container {
  display: flex;
  margin-bottom: 0.5rem;
}

.cdp_title-container {
  display: flex;
  margin-bottom: 5px;
}

.related_categories__title-icon {
  margin-right: 10px;
  font-size: 18px;
  color: var(--bdw-green);
}

.related_categories__title-text {
  font-size: 20px;
  line-height: 22px;
  margin: 0;
  font-weight: 500;
}

.active {
  background-color: unset !important;
  color: unset !important;
  opacity: unset !important;
}
.success {
  display: block !important;
}
.error {
  display: none !important;
}
.btn-grilla-upd {
  font-size: 27px;
}

.btn-grilla-del {
  fill: #f29718 !important;
  border-style: none;
  background-color: transparent;
  display: block;
  padding: 0px 10px;
}
.btn-grilla-del svg {
  fill: #f29718 !important;
  width: 21px;
  height: 21px;
}

.m-lef {
  margin-left: 5px;
}
.m-rig {
  margin-right: 5px;
}

.text-left {
  text-align: left;
  float: left;
}

.tabla-ejemplo {
  font-size: 13px;
  color: #999;
  text-align: center;
  border: 1px solid #ccc;
  margin: 0 auto;
}
.tabla-ejemplo th {
  border: 1px solid #ccc;
  padding: 4px;
}
.tabla-ejemplo td {
  border: 1px solid #ccc;
}

/* datatablre */
.dApqnJ {
  display: block !important;
}

.w-100 {
  width: 100%;
}
.header-1 {
  float: left;
  margin: 15px 0 0 0;
}
.header-2 {
  float: right;
  display: contents;
  margin: 18px 0 0 0;
}
/* .rdt_Table {
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%) !important;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  font-size: 14px;
  padding: 0px;
} */

nav.rdt_Pagination:nth-child(0) {
  display: none;
}

/*.rdt_Table > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; 
}*/
/* .rdt_Table > :not(caption) > * > * {
  border-width: 0 1px;
}
.rdt_Table > .rdt_TableBody > div:nth-of-type(odd) > * {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: #dee2e6;
  color: #2e2e2e;
}
.rdt_Table .rdt_TableCol {
  text-align: center !important;
  padding: 20px !important;
  color: #2e2e2e;
  font-weight: 600;
} */

/* .rdt_Table .rdt_TableCell {
  vertical-align: inherit;
  border-left: 1px solid #dee2e6 !important;
  font-size: 13px !important;
  justify-content: space-around;
} */

/* .rdt_TableHeadRow {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.rdt_TableHeadRow .glyQNg {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.rdt_TableCell div {
  white-space: unset !important;
} */

div[data-tag='allowRowEvents'] {
  white-space: unset !important;
  text-align: center;
  margin: 0 auto;
}

div[data-column-id='1'],
div[data-column-id='2'],
div[data-column-id='3'],
div[data-column-id='4'],
div[data-column-id='5'],
div[data-column-id='6'],
div[data-column-id='cat_origen'],
div[data-column-id='cat_coleccion'],
div[data-column-id='cat_destino'],
div[data-column-id='section_categ'],
div[data-column-id='skuprimary'],
div[data-column-id='skurelateds'] {
  white-space: unset !important;
  text-align: center;
  margin: 0 auto;
}

/* header {
  background-color: transparent !important;
  padding: 15px 0px 5px 0px !important;
} */
div.rdt_Table {
  font-size: 14px;
  padding: 0px;
}

nav.rdt_Pagination select {
  margin: 0 5px;
  color: #2fad7e !important;
  padding: 0 8px;
  background: #fff !important;
  border: 1px solid #ccc !important;
  font-weight: 500;
}

nav.rdt_Pagination span {
  font-weight: 700;
}
nav.rdt_Pagination:nth-child(0) {
  display: none !important;
}

.w-20-pc {
  width: 20%;
}
.inXiUS {
  outline: none;
}

.gMliOo,
nav.rdt_Pagination select {
  display: none !important;
}

nav.rdt_Pagination svg:first-of-type {
  display: none;
}

#pagination-first-page:not(:disabled),
#pagination-previous-page:not(:disabled),
#pagination-next-page:not(:disabled),
#pagination-last-page:not(:disabled),
#pagination-first-page:hover:not(:disabled),
#pagination-previous-page:hover:not(:disabled),
#pagination-next-page:hover:not(:disabled),
#pagination-last-page:hover:not(:disabled) {
  background-color: #6529a1;
}

#pagination-first-page svg,
#pagination-previous-page svg,
#pagination-next-page svg,
#pagination-last-page svg {
  display: block !important;
  fill: rgba(255, 255, 255, 0.7);
}

nav.rdt_Pagination {
  margin: 20px 0;
  border: none;
  color: #2e2e2e;
}

.nav-link {
  color: #2fad7e;
}

.nav-link:hover,
.nav-link:focus {
  color: #2fad7e;
}

.label-default {
  background-color: #777;
  display: inline;
  padding: 2px;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn-defa {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.sc-iveFHk {
  margin: 20px 0px;
}

.select_cross {
  height: 36px !important;
  padding: 6px !important;
}

.separador-hr {
  color: #ccc;
  margin: 10px 50px;
  display: block;
  padding: 10px;
}
.separador-hr1 {
  color: #6e6d6d;
  margin: 10px 50px;
  display: block;
  padding: 10px;
}

#myTab {
  margin: 50px 30px 0px 30px;
}
.botonera {
  margin-bottom: 50px;
}

button.botones_target {
  color: #2e2e2e;
  width: 275px;
}
button.botones_target_Mshop {
  color: #2e2e2e;
  width: 250px;
}
@media (max-width: 1400px) {
  button.botones_target_Mshop {
    width: 200px;
  }
}
button.botones_target_Mshop_Active {
  background-color: var(--bdw-green);
  color: #fff;
  outline: none;
}
button.active {
  color: #2fad7e !important;
  font-weight: 500;
}
button.btn-add-espec {
  margin: 10px 0;
}
h6.titulo {
  margin-left: 40px;
}

.tab-content {
  border: 1px solid #dee2e6;
  padding-top: 25px;
  margin: 0px 30px 30px 30px;
  border-top: none;
  padding-bottom: 20px;
}

button.btn-border {
  border: 1px solid #dfdfdf;
}

.box-specification {
  background-color: #fcfcfc;
  padding: 15px;
  width: 95%;
  margin-left: 30px;
  /*border: 1px solid #f6f3f3;*/
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
}

.select-category {
  display: flex;
  font-size: 16px !important;
  width: 85%;
}

/*div.swal2-container .swal2-close {
  display: block !important;
}*/

th.header-example {
  background: #eee;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.mr-2 {
  margin-right: 2px;
}

.especificacion_texto_info {
  border: 1px solid #dee2e6;
  padding-top: 25px;
  margin: 15px 100px 30px 0px;
  border-top: none;
  padding-bottom: 20px;
  left: 16%;
  width: 57%;
}
.alert-heading {
  font-size: 18px;
}

.alert-dismissible .btn-close {
  z-index: 0 !important;
}
.container-btn-spec {
  display: flex;
  align-items: center;
}

.container-body-tab {
  background-color: #fff;
  border: 1px solid #e2dede;
  padding: 30px 20px 20px;
  margin: 0px auto 30px;
  border-top: 0px;
  border-radius: 0 0 5px;
}

.container-tab {
  padding-left: 0px;
  padding-right: 0px;
}

/*.mr-2{
  margin-left: 2px;
}*/
