.shelf-inner {
  padding: 10px 20px;
  background: #fff;
  display: flex;
  margin: 5px;
  align-items: center;
  outline: none;
  justify-content: space-between;
  /* background-image: url(../../../img/Home.svg); */
  /* background-size: 75px; */
  /* background-repeat: no-repeat; */
  /* background-position: right; */
}

.shelf-inner span {
  padding-top: 5px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  width: auto; /*Que confirme paz para ver que onda con esto 130px;*/
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 15px;
}

.shelf-inner:hover,
.shelf-inner:active,
.shelf-inner:focus {
  background-color: var(--bdw-green);
  background-color: var(--bdw-green);
  color: #fff;
  outline: none;
}

.shelf-inner:hover > svg,
.shelf-inner:active > svg,
.shelf-inner:focus > svg,
.shelf-inner:visited > svg {
  fill: #fff;
}

.sectionBackground {
  background-color: var(--bdw-green);
  color: #fff !important;
  outline: none;
}

.sectionBackground > svg {
  filter: invert(1);
}

.section-box {
  width: 19%;
  border: none;
}

.table {
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  font-size: 13px;
}
table.table.table-sm.table-striped.table-bordered tbody tr {
  text-align: center;
}

.main-pagination-container {
  display: flex;
  justify-content: space-between;
}

.pagination-container {
  display: flex;
  flex-direction: row-reverse;
}
.pagination-container__text {
  margin-top: 10px;
}
h6.pagination-container__text {
  font-size: 15px;
}

.page-link__custom {
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dde2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  opacity: 0.5;
}

.active {
  background-color: var(--bdw-green);
  color: #fff;
  opacity: 1;
}

.manage-grid-button,
.manage-grid-button-gray {
  border-style: none;
  background-color: transparent;
  display: block;
  cursor: pointer;
}

.btn-agregar{
  float: left;
  border: solid 1px#dfdfdf;
}

.manage-grid-text {
  margin: 0;
}

.pagination-control {
  border-style: none;
  background: transparent;
}

.table > thead {
  background: white;
}
.table > :not(:first-child) {
  border-top: transparent;
}
tbody:nth-child(even) {
  background: white;
}
.manage-grid-button svg {
  fill: var(--bdw-green);
  width: 19px;
  margin: 0 5px;
}

.manage-grid-button svg#red {
  fill: #e45346;
}

tr.tr_fila {
  vertical-align: middle;
}

table.table.table-sm.table-striped.table-bordered td:last-child {
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: space-evenly;
  padding: 0;
  border: none;
}

.manage-grid-button-gray svg {
  fill: #f7b0b0;
}

.shelves {
  margin-top: 20px;
}

.shelves__wrapper {
  padding: 20px;
  background: #fff;
}

.shelves__title-container {
  display: flex;
  margin-bottom: 30px;
}

.shelves__title-icon {
  margin-right: 10px;
  font-size: 25px;
  line-height: 20px;
  color: var(--bdw-green);
}

.shelves__title-text {
  font-size: 20px;
  margin-top: 4px;
}

.shelves__endpoint-selector-container {
  display: flex;
}
.shelves__buttons-container {
  display: flex;
  justify-content: center;
}

.shelves__endpoint-selector-text,
.vtexskuconsult__text {
  text-align: left;
  vertical-align: middle;
  margin: 10px 2rem 0 0;
  padding: 0 0 0 45px;
}

.shelves__endpoint-selector-textArea {
  text-align: center;
  vertical-align: middle;
  margin: 0 2rem 0 0;
  padding: 0 0 0 20px;
}

.shelves__input-w100 {
  width: 100%;
  margin: 0 2rem 0 0;
}

.shelves-subComponents__container {
  margin-top: 30px;
}

.shelves select,
.vtexskuconsult__input,
.shelves input {
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  height: 35px;
}
.shelves input {
  width: 50%;
}
.shelves button {
  background: #262324;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  margin: 10px 8px;
  width: 175px;
  height: 40px;
}

.button_container {
  display: flex;
  width: 400px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  left: 14.5%;
}
.ppal_button {
  background: var(--bdw-green) !important;
}
.shelves__preview-button {
  justify-content: flex-end;
  display: flex;
}
.shelves__wrapper h6 {
  font-size: 15px;
  font-weight: 700;
}
.shelves__p {
  font-size: 12px;
  font-weight: 600;
  margin-top: 1rem;
  margin-left: 5rem;
}

.ml-20{
  margin-left: 30px;
}

input::-webkit-file-upload-button {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  background: var(--bdw-green) !important;
  cursor: pointer;
  color: #fff;
}

.switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 44px;
  margin: 0 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 0px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6529A1;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6529A1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

.panel-addtional-fields {
  border: 1px solid #e3e1e1;
  background: #eee;
  border-radius: 10px;
  padding: 10px 0px;
}

.box-select-endpoint {
  height: 34px;
  background: #fcfbfb;
  color: #151313;
  font-weight: 500;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  width: 66%;
}

.margin-0 {
  margin: 0px;
}

.box-searchfor {
  width: 53%;
  background: none;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
}

.select_paginas {
  color: #2fad7e;
  padding: 0 8px;
  outline: none;
}

.show-options {
  align-items: center;
}
p.show-options__text {
  font-weight: 500;
  font-family: 'Poppins';
  margin: 0;
}

/* spinner */
.spinner_v {
  display: block;
  float: left;
}
.spinner_text {
  margin: 22px 0px 20px 10px;
  clear: both;
  position: relative;
  font-size: 24px;
}

.spinner_opacity {
  opacity: 0.3;
  cursor: not-allowed;
}
.st0 {
  fill: #262324 !important;
}

.textarea-endpoint {
  width: 88%;
  background: rgb(247 247 247);
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 43px;
  padding: 10px;
}

.text-right {
  text-align: right;
}

.box_accordion{
    background-color: #fcfcfc;    
    width: 95%;
    margin-left: 30px;    
    box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
    margin-bottom: 20px;
}

.disp-none {
  display: none !important;
}
.disp-block {
  display: inherit !important;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn{
  border-color: #c0cfc9 !important;
}

.mt-40{
  margin-top: 40px;
}
.mb-40{
  margin-bottom: 40px;
}
.mr-20{
  margin-right: 20px;
}
.ml-20{
  margin-left: 20px;
}
.ml-150{
  margin-left: 150px;
}
.p-20{
  padding: 20px !important;
}

.contenedor-btn-grid2{
  display: flex;
    margin: 20px 0px;
    align-items: center;
}

.style-btn-volver{
  padding: 4px 57px !important;
}

.manage-grid-button-del {
  fill: red;
  border-style: none;
  background-color: transparent;
  display: block;
}

/* .modal_box button{
    background:transparent !important;     
    border-radius: 2px;
    font-size: 16px;
    color: black !important;
    margin: 0px !important; 
    width: auto !important;
    height: auto !important;
} */

.center-btn-save{
  text-align: center;
  margin: 0 auto;
  align-items: center;
  display: block;
}

.title-grid-gondola-grid{
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}

.sombra-generica{
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);  
}

.border-boton{
  border-radius: 4px;

  border-right: 1px solid #bdbbbb;
  border-bottom: 1px solid #bdbbbb;
}

.box-icon-admin{
  margin-top: 0px;
    margin-bottom: -30px;
}
.margin-32{
  margin-left: -32px !important;
}
.padding-left-70{
  padding-left: 70px;
}
.ancho-340{
  width: 340px;
}
.margin-left-12{
  margin-left: 12px;
}

.swal2-popup.swal2-toast .swal2-actions{
  margin: 5px auto !important;
}

.margin-top-6{
  margin-top: 6px;
}

.margin-left-5{
  margin-left: 5px !important;
}
.margin-sinvenc{
  margin : 0px 0 0 5px !important;
}

.hide{
  display: none !important;
}

.mt-8{
  margin-top: 8px;
}

.icon-date-1{
  color: #2eac7e;
  margin: 14px 5px 0 5px;
}

.icon-date-2{
  color: #2eac7e;
  margin: 0px 5px 0 5px;
}

.lnk-tit a{
  color: #2eac7e;
}

.caja-btn-verif .shelves__preview-button{
  display: block !important;
}