.under_construction {
  width: 700px;
  height: 600px;
  background-image: url(https://storage.cdn.braindw.com/pruebaio_produccion/script/files/2023/08/10/underc.svg);
  background-repeat: no-repeat;
  margin: 0 auto;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}
.mr-6 {
  margin-right: 20rem !important;
}
.mt-6 {
  margin-top: 2.4rem;
}
.pl {
  padding-left: 2rem;
}
.hor_line {
  border-bottom: 1px solid #dbdbdb;
}
.bg_styles {
  border-radius: 3px;
  background: var(--color-white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
}
.three_cards {
  width: 100%;
  max-width: 405px;
  margin: 20px 0 5px;
  padding: 15px 20px 20px;
}
.green_circle {
  width: 55px;
  height: 55px;
  border-radius: 55px;
  background: var(--primary-800, #2bae7e);
  display: inline-block;
}
.three_cards p {
  color: var(--greys-grey-900, #444);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin: 0 0 0 15px;
}
.three_cards span {
  color: var(--greys-grey-900, #444);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  line-height: 10px;
}
.claim_sm {
  color: #000;
  font-size: 10px;
  font-family: Poppins;
  font-style: italic;
  font-weight: 400;
  line-height: 32px;
  margin: 0 0 5px auto;
  width: 260px;
}
.search_card {
  width: 100%;
  max-width: 1315px;
  margin: 0 auto 25px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn_gral {
  background: white;
  border-radius: 8px;
  border: 1px solid var(--greys-grey-500, #dbdbdb);
  color: var(--greys-grey-800, #666);
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  padding: 0 15px;
}
.btn_green {
  background: white;
  border-radius: 8px;
  border: 1px solid var(--primary-800, #2bae7e);
  color: var(--primary-800, #2bae7e);
}
.searchfor {
  background: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  width: 30%;
}
.search_txt {
  color: var(--greys-grey-800, #666);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: none;
  width: 100%;
  outline: none;
}
.dropdw {
  border-radius: 8px;
  font-size: 14px;
  padding: 0.35rem 2rem 0.35rem 0.75rem;
  border: none;
  background-color: var(--greys-blue-grey-300, #f9fafb);
}
.form-select:focus {
  border-color: #ccc;
  outline: 0;
  box-shadow: none;
}
option:hover,
option:focus,
option:active,
option:checked {
  color: #444;
  background-color: #ccc;
}
.list_card {
  width: 100%;
  max-width: 1315px;
  margin: 0 auto 25px;
  padding: 20px;
}
.list_card h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  margin: 0 20px 0 0 !important;
}
.list_card h2.selected {
  color: var(--primary-800, #2bae7e);
  border-bottom: 2px solid var(--primary-800, #2bae7e);
}
.list_card h3 {
  color: var(--secondary-900, #f29718);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 0 10px;
}
.txt-send-camp {
  font-weight: 700;
}
.list_card p {
  margin: 0 10px 0 0;
  color: var(--greys-grey-800, #666);
  font-size: 13px;
  font-weight: 300;
  font-family: 'poppins';
}
.linea {
  height: 1px;
  background-color: var(--greys-grey-500, #dbdbdb);
}
.lh_txt {
  line-height: 30px;
}
.align-content-evenly {
  align-content: space-evenly;
}
.regla_btn_gral {
  background-color: #fff;
  box-shadow: (0px 4px 20px rgba(238, 238, 238, 0.5));
  height: 40px;
  padding: 0 10px;
  border: none;
  border-radius: 3px;
  opacity: 0.5;
}
.regla_btn_gral span {
  font-size: 16px;
  font-weight: 600;
  color: #444444;
}
svg.regla {
  opacity: 0.5;
}
.regla_btn_selected {
  opacity: 1 !important;
}
.regla_frame {
  border-radius: 3px;
  background: var(--color-white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
  color: #444444;
  padding: 30px;
}
.regla_frame .col_lf {
  width: 20%;
  border-right: 1px solid #dbdbdb;
}
.regla_frame .col_rt {
  width: 80%;
  padding-left: 25px;
}
.regla_frame h1 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 20px !important;
}
.regla_frame h3 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 20px 0;
}
.regla_frame span {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Poppins';
}
.regla_frame p,
.regla_frame li {
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 20px;
}
li.li_selected {
  background-color: #f4f4f4;
  padding: 10px;
  border-left: 3px solid #f29718;
  width: 70%;
  font-weight: 600;
}
.regla_cards {
  width: 30%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #f4f4f4;
  border-radius: 3px;
  margin: 0;
  padding: 15px;
}
.configuracion h2 {
  color: var(--greys-grey-800, #666);
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.configuracion span {
  color: var(--greys-grey-800, #666);
  font-size: 14px;
  font-weight: 300;
}
.config_btn_gral {
  background-color: #f4f4f4;
  box-shadow: 0px 4px 20px rgba(238, 238, 238, 0.5);
  height: 40px;
  padding: 0 10px;
  border: none;
  border-radius: 3px;
  opacity: 0.5;
}
.config_btn_gral span {
  font-size: 14px;
  font-weight: 600;
  color: #444444;
}
.config_claim {
  color: var(--greys-grey-800, #666);
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
}
.configuracion input,
.configuracion select {
  border-radius: 5px;
  border: none;
  background: #f4f4f4;
  color: var(--greys-grey-800, #666);
  font-size: 14px;
  font-weight: 400;
  outline: none;
  width: 620px;
  height: 30px;
  padding: 2px 10px;
  border-right: 8px solid transparent;
  border: 1px solid #ced4da;
}

input.invalid {
  border: 1px solid #db164b;
}

select.dos,
input.tres {
  width: auto;
}
input.dos {
  width: 50px;
  text-align: center;
}
.etiq_color {
  border-radius: 5px;
  background: var(--colors-amber-100, #fef3c7);
  color: var(--secondary-900, #f29718) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 5px 10px;
}

.templates_imgs {
  background-color: #f4f4f4;
  box-shadow: 0px 4px 20px rgba(238, 238, 238, 0.5);
  padding: 10px;
  border: none;
  border-radius: 3px;
}
ul li figure {
  padding: 5px;
  position: relative;
  margin: 0;
}
ul li figure:hover figcaption {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
ul li figcaption {
  display: none;
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 15px;
  color: #fff;
}
ul li figcaption p {
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px !important;
}

.analytics-title-cards {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
}

.sales_resume {
  background-color: #f2f2f2;
  box-shadow: 0px 4px 20px rgba(238, 238, 238, 0.5);
  padding: 10px;
  border: none;
  border-radius: 16px;
}

.sales_subtitle {
  color: #f29718;
}

.text_poppins {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
}

.border-sales {
  border: 2px solid rgb(232, 232, 237);
  position: absolute;
  right: 0;
  left: 0;
  display: none;
}

.border-sales-vertical {
  border: 2px solid rgb(232, 232, 237);
  position: absolute;
  top: 130px;
  bottom: 0px;
  left: 109px;
  display: none;
}

.user-info-title {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
}

.sales-image {
  position: relative;
  top: 4px;
}

.sales-value {
  padding-top: 5px;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
  white-space: nowrap;
}

.doughnut-analytic-card-region {
  width: 50rem;
  height: 30rem;
}

.doughnut-analytic-container-region {
  width: 40rem;
  height: 35rem;
  position: relative;
  bottom: 6rem;
}

.doughnut-analytic-card {
  width: 22rem;
  padding: 10px 10px 10px 10px;
}

.doughnut-analytic-container {
  width: 20rem;
  height: 13rem;
  transform: scale(1.1);
  transform-origin: -6rem 60px;
}

.doughnut-analytic-container-age {
  width: 20rem;
  height: 13rem;
  transform: scale(1.3);
  transform-origin: -6rem 60px;
}

.sales-sub-data {
  display: none;
}

.calendar-container-start {
  position: relative;
  left: 119px;
  top: 25px;
}

.calendar-container-end {
  position: relative;
  left: 414px;
  bottom: 359px;
}

.day-picker-label {
  font-size: 14px;
  font-weight: bold;
  color: #6529a1;
  position: relative;
  top: 72px;
  left: 129px;
}

.jPphKt {
  position: static !important;
  display: block !important;
}

.em-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.7);
  transform-origin: 95px 0px;
}

.automation-table-top-buttons {
  display: flex;
  justify-content: space-between;
}

.analytics-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 2px solid;
  margin: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: #00868b;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
}

@media (min-width: 1702px) and (max-width: 1700px) {
}

@media (min-width: 1701px) {
  .doughnut-analytic-card-region {
    width: 42rem;
    height: 27rem;
  }
  .doughnut-analytic-container-region {
    width: 32rem;
    height: 32rem;
  }
  .doughnut-analytic-card {
    width: 31.5%;
    height: 16rem;
  }
}

@media (min-width: 1601px) and (max-width: 1700px) {
  .doughnut-analytic-card-region {
    width: 40rem;
    height: 27rem;
  }
  .doughnut-analytic-container-region {
    width: 32rem;
    height: 32rem;
  }
  .doughnut-analytic-card {
    width: 31.5%;
    height: unset;
  }
  .doughnut-analytic-container {
    transform-origin: -29% 16%;
  }
}

@media (min-width: 1501px) and (max-width: 1600px) {
  .doughnut-analytic-card-region {
    width: 40rem;
    height: 27rem;
  }
  .doughnut-analytic-container-region {
    width: 32rem;
    height: 32rem;
  }
  .doughnut-analytic-container {
    transform-origin: -29% 16%;
  }
  .doughnut-analytic-card {
    width: 31.5%;
  }
}

@media (min-width: 1412px) and (max-width: 1500px) {
  .doughnut-analytic-card-region {
    width: 39rem;
  }
  .doughnut-analytic-card {
    width: 31.5%;
    height: unset;
  }
}

@media (min-width: 1401px) and (max-width: 1464px) {
  .doughnut-analytic-card-region {
    width: 48.7%;
    height: 27rem;
  }
  .doughnut-analytic-container-region {
    width: 100%;
    height: 19rem;
    position: relative;
    bottom: 5%;
  }
  .doughnut-analytic-card {
    width: 31.5%;
  }
  .doughnut-analytic-container {
    transform-origin: -29% 16%;
  }
}

@media (min-width: 1465px) {
  .doughnut-analytic-card-region {
    width: 48.8%;
    height: 27rem;
  }
  .doughnut-analytic-container-region {
    width: 100%;
    height: 19rem;
    position: relative;
    bottom: 5%;
  }
  .campaign-card {
    width: 15.3rem;
  }
  .paddingLeft11px {
    padding-left: 11px;
  }
  .sales-card {
    padding: 20px 11px 14px 10px;
    position: relative;
  }
  .sales-card-2 {
    padding: 20px 11px 14px 10px;
    position: relative;
  }
  .sales-subtitle {
    font-size: 11px;
    font-weight: 400;
    font-family: Raleway;
    white-space: nowrap;
  }
  .border-sales-vertical {
    top: 128px;
  }
  .sales-resume-icon {
    border-right: 1px solid rgb(181, 181, 181);
    margin-right: 14px;
    padding-top: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1400px) {
  .doughnut-analytic-card-region {
    width: 48.6%;
    height: 27rem;
  }
  .doughnut-analytic-container-region {
    width: 32rem;
    height: 32rem;
  }
  .doughnut-analytic-card {
    width: 24rem;
  }
  .doughnut-analytic-container {
    transform-origin: 1rem 60px;
  }
  .campaign-card {
    width: 14.3rem;
  }
  .sales-card {
    padding: 20px 11px 16px 10px;
    position: relative;
  }
  .sales-card-2 {
    padding: 20px 11px 0px 10px;
    position: relative;
  }
  .sales-title {
    white-space: nowrap;
  }
  .sales-subtitle {
    font-size: 11px;
    font-weight: 400;
    font-family: Raleway;
    white-space: unset;
  }
  .sales_resume {
    width: 240px;
    height: 115px;
    margin-left: 25px;
  }
  .sales-resume-icon {
    border-right: 1px solid rgb(181, 181, 181);
    padding-top: 10px;
    margin-right: 14px;
    padding-right: 10px;
  }
}

@media (min-width: 1237px) and (max-width: 1273px) {
  .doughnut-analytic-card-region {
    width: 48.5%;
    height: 27rem;
  }
  .doughnut-analytic-container-region {
    width: 32rem;
    height: 32rem;
  }
}

@media (max-width: 1236px) {
  .campaign-card {
    width: 12.3rem;
  }
  .sales-card {
    padding: 20px 11px 16px 10px;
    position: relative;
  }
  .sales-card-2 {
    padding: 20px 11px 16px 10px;
    position: relative;
  }
  .sales-title {
    font-family: 'Poppins';
    font-size: 14px;
  }
  .border-sales-vertical {
    left: 84px;
  }
  .sales-subtitle {
    font-size: 7px;
    font-weight: 400;
    font-family: Raleway;
    white-space: unset;
  }
  .sales_resume {
    width: 200px;
    height: 115px;
    margin-left: 25px;
  }
  .user-info-title {
    font-size: 17px;
  }
  .doughnut-analytic-card-region {
    width: 48%;
    padding: 10px 10px 10px 0px;
  }
  .doughnut-analytic-card {
    width: 20rem;
    padding: 10px 10px 10px 0px;
  }
}

@media (max-width: 1050px) {
  .campaign-card {
    width: 10.3rem;
  }

  .border-sales-vertical {
    left: unset;
  }

  .sales-title {
    font-size: 9px;
    white-space: unset;
  }

  .sales-subtitle {
    font-size: 9px;
  }

  .sales_resume {
    width: 182px;
  }
}
