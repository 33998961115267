.automation__endpoint-selector-text,
.vtexskuconsult__text {
  padding: 0 0 0 0px !important;
}
.alto-250 {
  height: 250px !important;
}

div[data-column-id='title'],
div[data-column-id='resume'],
div[data-column-id='enabled'],
div[data-column-id='acc_categ'] {
  white-space: unset !important;
  text-align: center;
  margin: 0 auto;
}

.align-left {
  text-align: left !important;
}

.accordion-button:not(.collapsed) {
  color: #2fad7e !important;
  background-color: #f7f7f7 !important;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none;
}

.error-form-bdw{
  font-size: .875em;
    color: var(--bs-code-color);
    word-wrap: break-word;
}

/*.disabled{  
  background: #f4f4f4 !important;
  color: #999;
}*/
.color-red svg {
  fill: #b10505;
}