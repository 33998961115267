.padding-0 {
  padding: 0px !important;
}

.shelves__endpoint-selector-text,
.vtexskuconsult__text {
  margin: 10px 1rem 0 0 !important;
}

div[data-column-id='Term'],
div[data-column-id='Skus'],
div[data-column-id='ProductIds'],
div[data-column-id='DateAdded'],
div[data-column-id='DateUpdated'] {
  white-space: unset !important;
  text-align: center;
  margin: 0 auto;
}


select:disabled {
  background: #f4f2f2;
  color: #b7b7b7;
}
.brandPriority__title-container{
  display: flex;
  margin-bottom: 15px;
  align-items: center
}

/******* BADGE ******/
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}