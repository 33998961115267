.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.modal_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.modal_box {
  position: relative;
  width: 80%;
  margin: 8% auto;
  padding: 50px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: white;
  cursor: auto;
}

.modal_content {
  margin-top: 70px;
  color: #6b6b6b;
  font-size: 16px;
  position: relative;
  cursor: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
}
.modal_content::-webkit-scrollbar {
  width: 12px;
  margin-top: 5px;
}
.modal_content::-webkit-scrollbar-thumb:hover {
  background: #165543;
}
.modal_content::-webkit-scrollbar-thumb {
  background: #2fad89;
  border-radius: 8px;
  outline: 1px solid slategrey;
}
.modal_content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

.badge-success {
  color: #fff;
  background-color: #2fad7e;
}

.modal_title {
  color: #212529;
  font-size: 24px;
  margin: -40px 10px;
  text-align: left;
  font-weight: 600;
}

.modal_close {
  position: absolute;
  top: 20px;
  right: 20px;
  transition: transform 250ms ease-in-out;
  transform-origin: 50% 50%;
}

.modal {
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
}

.modal_close-btn {
  text-align: end;
  color: #212529;
  font-size: 18px;
  cursor: pointer;
  /* background: #f7f6f6 no-repeat; */
  padding-right: 10px;
  padding-left: 10px;
  margin-top: -16px;
  border-radius: 10px;
}
.modal_close:hover {
  transform: rotate(180deg);
}

/* NOTEBOOK */
@media (max-width: 1400px) {
  .modal_box {
    position: relative;
    width: 90% !important;
    margin: 3% auto;
    padding: 50px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
    cursor: auto;
  }

  .modal_content {
    margin-top: 70px;
    color: #6b6b6b;
    font-size: 16px;
    position: relative;
    cursor: auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 500px;
  }
}
