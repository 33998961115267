.ms-instructivo a,
.landing_ms_container a {
  color: #4c1f79;
  text-decoration: underline;
}
.ms-instructivo a:hover,
.landing_ms_container a:hover {
  color: #331551;
}

img.ms_header-logo-image {
  width: 170px;
}
h1.ms_hero-title {
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0px;
}
@media (max-width: 640px) {
  h1.ms_hero-title {
    font-size: 38px;
    line-height: 42px;
  }
}
p.ms_hero-paragraph {
  margin-top: 0;
  margin-bottom: 24px;
  font-family: 'Raleway';
  font-size: 17px;
  line-height: 26px;
}

.ms_hero-cta input {
  font-size: 15px;
  line-height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  outline: none;
  margin: 0 5px 0 0;
  padding: 10px;
}

.ms_text-sm {
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0;
}
.text-xs {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}
.text_claim {
  font-size: 10px;
  font-style: italic;
}

.landing_ms_container,
.landing_ms_container-sm {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.landing_ms_container h2,
h2.ms_section-title {
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0px;
  margin-top: 16px !important;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 700;
}

@media (max-width: 640px) {
  .landing_ms_container h2 {
    margin-bottom: 10px;
  }
  .landing_ms_container h2,
  h2.ms_section-title {
    font-size: 18px;
    line-height: 28px;
  }
}

h4.ms_feature-title {
  font-weight: 700;
  letter-spacing: 0;
}
@media (min-width: 481px) {
  .landing_ms_container,
  .landing_ms_container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.landing_ms_container {
  max-width: 1128px;
}
.landing_ms_container-sm {
  max-width: 900px;
}
.landing_ms_container .landing_ms_container-sm {
  max-width: 800px;
  padding-left: 0;
  padding-right: 0;
}

.ms_has-bottom-divider {
  position: relative;
}

.ms_has-bottom-divider::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #d8d8d8;
}

.sr .ms_has-animations .ms_is-revealing {
  visibility: hidden;
}
.ms_has-animations .anime-element {
  visibility: hidden;
}
.anime-ready .ms_has-animations .anime-element {
  visibility: visible;
}

.ms_button {
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #242830;
  color: #fff !important;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  justify-content: center;
  padding: 12px 24px;
  text-align: center;
  white-space: nowrap;
  align-items: center;
}
.ms_button:hover {
  background: #262a33;
}
.ms_button:active {
  outline: 0;
}
.ms_button::before {
  border-radius: 2px;
}
.ms_button-sm {
  padding: 8px 24px;
  height: 32px;
}
.ms_button-primary {
  background: #00868b;
  background: linear-gradient(65deg, #00868b 0, #005a5d 100%);
}
.ms_button-primary:hover {
  background: #005a5d;
  background: linear-gradient(65deg, #005a5d 0, #00868b 100%);
}
.ms_button-block {
  display: flex;
}
.ms_button-block {
  display: flex;
  width: 100%;
}
@media (max-width: 640px) {
  .ms_button-wide-mobile {
    width: 100%;
    max-width: 280px;
  }
}
.ms_site-header {
  padding: 24px 0;
}
.ms_site-header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 640px) {
  .ms_site-header-inner {
    justify-content: center;
  }
}
.header-links {
  display: inline-flex;
}
.header-links li {
  display: inline-flex;
}
.header-links a:not(.ms_button) {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 600;
  color: #8a94a7;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 16px;
  padding: 8px 24px;
}

@media (min-width: 641px) {
  .ms_site-header,
  .ms_site-footer {
    position: relative;
  }
  .ms_site-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background: #242830;
    background: linear-gradient(
      80deg,
      rgba(228, 228, 228, 0.5) 0%,
      rgba(228, 228, 228, 0) 100%
    );
    transform-origin: 0;
    transform: skewY(-12deg);
  }
  .ms_hero-figure .ms_illust {
    width: 510px !important;
  }
}

.ms_hero-figure .ms_illust {
  width: 300px;
}

.ms_hero {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 88px;
}
.ms_hero-copy,
.ms_hero-figure {
  position: relative;
  z-index: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ms_hero-figure svg {
  width: 100%;
  height: auto;
}
.ms_hero-figure::before,
.ms_hero-figure::after {
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
}
.ms_has-animations .ms_hero-figure::before,
.ms_has-animations .ms_hero-figure::after {
  opacity: 0;
  transition: opacity 2s ease;
}
.anime-ready .ms_has-animations .ms_hero-figure::before,
.anime-ready .ms_has-animations .ms_hero-figure::after {
  opacity: 1;
}
.ms_hero-figure-box {
  position: absolute;
  top: 0;
  will-change: transform;
}
.ms_hero-figure-box-01,
.ms_hero-figure-box-02,
.ms_hero-figure-box-03,
.ms_hero-figure-box-04,
.ms_hero-figure-box-08,
.ms_hero-figure-box-09 {
  overflow: hidden;
}
.ms_hero-figure-box-01::before,
.ms_hero-figure-box-02::before,
.ms_hero-figure-box-03::before,
.ms_hero-figure-box-04::before,
.ms_hero-figure-box-08::before,
.ms_hero-figure-box-09::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-origin: 100% 100%;
}
.ms_hero-figure-box-01 {
  left: 103.2%;
  top: 41.9%;
  width: 28.03%;
  height: 37.37%;
  background: linear-gradient(to left top, #38d89d, rgba(0, 191, 251, 0));
  transform: rotateZ(45deg);
}
.ms_hero-figure-box-01::before {
  background: linear-gradient(to left, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(45deg) scale(1.5);
}
.ms_hero-figure-box-02 {
  left: 61.3%;
  top: 64.1%;
  width: 37.87%;
  height: 50.5%;
  background: linear-gradient(to left top, #6529a1, rgba(2, 112, 215, 0));
  transform: rotateZ(-45deg);
}
.ms_hero-figure-box-02::before {
  background: linear-gradient(to top, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(-45deg) scale(1.5);
}
.ms_hero-figure-box-03 {
  left: 87.7%;
  top: -56.8%;
  width: 56.81%;
  height: 75.75%;
  background: linear-gradient(to left top, #38d89d, rgba(0, 191, 251, 0));
}
.ms_hero-figure-box-03::before {
  background: linear-gradient(to left, #15181d 0%, rgba(21, 24, 29, 0) 60%);
  transform: rotateZ(45deg) scale(1.5);
}
.ms_hero-figure-box-04 {
  left: 54.9%;
  top: -8%;
  width: 45.45%;
  height: 60.6%;
  background: linear-gradient(to left top, #6529a1, rgba(2, 112, 215, 0));
  transform: rotateZ(-135deg);
}
.ms_hero-figure-box-04::before {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  transform: rotateZ(-45deg) scale(1.5);
}
.ms_hero-figure-box-05,
.ms_hero-figure-box-06,
.ms_hero-figure-box-07 {
  background: linear-gradient(to left top, #6529a1, rgb(72 72 72 / 30%));
}
.ms_hero-figure-box-05 {
  left: 17.4%;
  top: 13.3%;
  width: 64%;
  height: 73.7%;
  transform: perspective(500px) rotateY(-15deg) rotateX(8deg) rotateZ(-1deg);
}
.ms_hero-figure-box-06 {
  left: 65.5%;
  top: 6.3%;
  width: 30.3%;
  height: 40.4%;
  transform: rotateZ(20deg);
}
.ms_hero-figure-box-07 {
  left: 1.9%;
  top: 42.4%;
  width: 12.12%;
  height: 16.16%;
  transform: rotateZ(20deg);
}
.ms_hero-figure-box-08 {
  left: 27.1%;
  top: 81.6%;
  width: 19.51%;
  height: 26.01%;
  background: #6529a1;
  transform: rotateZ(-22deg);
}
.ms_hero-figure-box-08::before {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.48) 100%
  );
  transform: rotateZ(45deg) scale(1.5);
}
.ms_hero-figure-box-09 {
  left: 42.6%;
  top: -17.9%;
  width: 6.63%;
  height: 8.83%;
  background: #38d89d;
  transform: rotateZ(-52deg);
}
.ms_hero-figure-box-09::before {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.64) 100%
  );
  transform: rotateZ(45deg) scale(1.5);
}
.ms_hero-figure-box-10 {
  left: -3.8%;
  top: 4.3%;
  width: 3.03%;
  height: 4.04%;
  background: #6529a181;
  transform: rotateZ(-50deg);
}

@media (max-width: 640px) {
  .ms_hero-cta {
    max-width: 280px;
    margin: 50px auto 0;
  }
  .ms_hero-cta .ms_button {
    display: flex;
  }
  .ms_hero-cta .ms_button + .ms_button {
    margin-top: 16px;
  }
  .ms_hero-figure::after,
  .ms_hero-figure-box-03,
  .ms_hero-figure-box-04,
  .ms_hero-figure-box-09 {
    display: none;
  }
  body
    > div
    > main
    > section.ms_hero
    > div
    > div
    > div.ms_hero-copy
    > div
    > input[type='email'] {
    margin: 0px 0px 10px 0 !important;
    width: 100% !important;
  }
}

@media (min-width: 641px) {
  .ms_hero {
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
  }
  .ms_hero-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ms_hero-copy {
    padding-right: 64px;
    min-width: 552px;
    width: 552px;
  }
  .ms_hero-cta {
    margin: 50px 0 0;
    position: relative;
    z-index: 1;
  }
  .ms_hero-cta .ms_button {
    min-width: 170px;
  }
  .ms_hero-figure svg {
    width: auto;
  }
}

.ms_features-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-right: -32px;
  margin-left: -32px;
}
.ms_features-wrap:first-of-type {
  margin-top: -16px;
}
.ms_features-wrap:last-of-type {
  margin-bottom: -16px;
}
.ms_feature {
  padding: 16px 32px;
  width: 380px;
  max-width: 380px;
  flex-grow: 1;
}
.ms_feature-inner {
  height: 100%;
}
.ms_feature-icon {
  display: flex;
  justify-content: center;
}

@media (min-width: 641px) {
  .ms_features-wrap:first-of-type {
    margin-top: -24px;
  }
  .ms_features-wrap:last-of-type {
    margin-bottom: -24px;
  }
  .ms_feature {
    padding: 32px 32px;
  }
}

.ms_pricing-header {
  margin-bottom: 48px;
}
.ms_pricing-tables-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-right: -12px;
  margin-left: -12px;
}
.ms_pricing-tables-wrap:first-child {
  margin-top: -12px;
}
.ms_pricing-tables-wrap:last-child {
  margin-bottom: -12px;
}
.ms_pricing-table,
.ms_pricing-table1 {
  position: relative;
  padding: 12px;
  width: 420px;
  max-width: 420px;
  flex-grow: 1;
}

.ms_pricing-table-features-title,
.ms_pricing-table-features li {
  border-bottom: 1px solid rgba(138, 148, 167, 0.24);
}
.ms_pricing-table-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 10px 24px;
}
.ms_pricing-table-inner > * {
  position: relative;
  width: 100%;
}
.ms_pricing-table-inner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 15px -5px rgb(0 0 0 / 20%);
  mix-blend-mode: multiply;
}
.ms_pricing-table-price-currency {
  color: #6529a1;
  font-size: 14px;
  margin-right: 5px;
}
span.ms_pricing-table-price-amount.h1 {
  font-size: 25px;
  font-weight: 600;
  color: #6529a1;
  padding: 0 5px;
}
.ms_pricing-table-features-title,
.ms_cta .ms_section-title {
  font-weight: 700;
}
.ms_pricing-table-features li {
  display: flex;
  align-items: center;
  padding: 14px 0;
  font-size: 13px;
}
.ms_pricing-table-features li span {
  margin-left: 10px;
}
.ms_pricing-table .ms_pricing-table-inner.ms_is-revealing {
  height: 450px;
}
.ms_pricing-table .ms_pricing-table-header.mt-3.pb-3 {
  bottom: 0px;
  position: absolute;
  width: 100%;
}
/*.ms_pricing-table-features li::before {
  content: '';
  width: 16px;
  height: 12px;
  margin-right: 16px;
  background-image: url('./images/descarga.svg');
  background-repeat: no-repeat;
}*/
.text-xxs {
  font-size: 12px;
}
.ms_pricing-table-cta {
  align-self: flex-end;
}
@media (min-width: 641px) {
  .ms_pricing-header {
    margin-bottom: 52px;
  }
}
.ms_cta {
  text-align: center;
}
.ms_cta .ms_section-inner {
  padding: 48px 16px;
}
.ms_cta-inner {
  position: relative;
  overflow: hidden;
}
.ms_cta-inner > * {
  position: relative;
}
.ms_cta-inner h2 {
  font-size: 17px;
  letter-spacing: 0px;
  text-align: center;
  font-weight: 700;
  margin: 0;
}
@media (min-width: 641px) {
  .ms_cta {
    text-align: left;
  }
  .ms_cta .ms_section-inner {
    padding: 40px 32px;
  }
  .ms_cta .ms_section-title {
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
  }
  .ms_cta-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ms_last-cta {
  font-size: 17px;
  font-weight: 400;
}
.ms_body-wrap {
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  mix-blend-mode: multiply;
  mix-blend-mode: normal;
}
main {
  flex: 1 0 auto;
}
.ms_section-inner {
  position: relative;
  padding-top: 48px;
  padding-bottom: 48px;
}
@media (min-width: 641px) {
  .ms_section-inner {
    padding-top: 45px;
    padding-bottom: 60px;
  }
}
.ms_site-footer {
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0px;
}
.ms_site-footer a {
  color: #8a94a7;
  text-decoration: none;
}
.ms_site-footer a:hover,
.ms_site-footer a:active {
  text-decoration: underline;
}
.ms_site-footer-inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 48px;
  padding-bottom: 48px;
  justify-content: center;
}
.ms_footer-copyright {
  flex: none;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}
@media (min-width: 641px) {
  .ms_site-footer {
    margin-top: 20px;
  }
  .ms_site-footer-inner {
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.opacity-tag {
  opacity: 0.3;
}

/*AGRADECIMIENTO*/
section.ms_features.section {
  position: relative;
}
.ms_feature2 {
  width: 100%;
  max-width: initial;
}
h4.ms_feature-title,
h5.ms_feature-title {
  font-weight: 700;
  letter-spacing: 0;
}
h4.color_primary {
  font-weight: 600;
}
h4 span {
  font-weight: 300;
  font-size: 20px;
  color: #6529a1;
}
.ms_cta-cta {
  text-align: center;
}
.ms_section-inner2 {
  padding: 0;
}
.ms_feature-icon img {
  height: 120px;
}
.color_primary {
  color: #6529a1;
}
.mockup-ms {
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.ms_feature-ilustraexito img {
  height: 345px;
}
.ms_ilustraconstr img {
  height: 450px;
}

/*Planes*/
.j-center {
  justify-content: center;
}
.borderPlan {
  border: 1px solid #feaf0b;
  border-radius: 10px;
  padding: 20px 30px 20px 30px;
  margin-right: 2rem;
}
.centerFlexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.monto {
  color: #feaf0b;
  font-size: 55px;
  font-weight: 700;
  margin-left: 5px;
  margin-right: 5px;
}
.montoSi {
  color: #feaf0b;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.m0 {
  margin: 0;
}
.ml-2 {
  margin-left: 1rem;
}
.ml-1 {
  margin-left: 0.5rem;
}

/*MODAL*/
.ms_overlay_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9995;
}

.ms_modal * {
  float: none !important;
  clear: both !important;
}
.ms_modal {
  position: fixed;
  z-index: 9999;
  max-width: 500px;
  max-height: 350px;
  height: 100%;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 5px rgb(0 0 0 / 20%);
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 50%;
  background-color: #fff;
  text-align: center;
  padding: 25px;
}
.ms_modal h2 {
  font-size: 25px;
  font-weight: 700;
}
.ms_modal fieldset {
  border: none;
  padding: 0;
}

.ms_modal_btn {
  width: 250px;
  height: 34px;
  border-radius: 3px;
  background: #6529a1;
  cursor: pointer;
  border: none;
  display: block;
  margin: 3px auto 15px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
}

.ms_modal_close {
  cursor: pointer;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 5px;
  z-index: 9999;
  margin: 5px;
  top: 5px;
  font-weight: 700;
}

/* PANEL */
.profile_bk {
  height: 759px;
  margin: 40px;
  border-radius: 3px;
  background: var(--color-white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(238, 238, 238, 0.5);
}
.profile_icon {
  background-image: url(./images/profile_icon.svg);
  background-repeat: no-repeat;
  margin: 0;
}
.texto-bullet {
  width: 65%;
  margin: 0 auto;
  float: none;
}
@media (max-width: 640px) {
  .texto-bullet {
    width: 95%;
  }
}

#root > div:nth-child(4) > p {
  font-size: 12px;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 12px !important;
  font-family: 'Poppins' !important;
}
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 30px 5px 10px !important;
}

.circulo-1 {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  background-color: #74ecc6;
  border: 1px solid #000;
  display: inline-block;
  margin-right: 5px;
}
.circulo-2 {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  background-color: #d6d6d6;
  border: 1px solid #000;
  display: inline-block;
  margin-right: 5px;
}
.circulo-3 {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  background-color: #2bae7e;
  border: 1px solid #000;
  display: inline-block;
  margin-right: 5px;
}

.shelf-inner.botones_target_Mshop_Active > svg {
  fill: #fff !important;
}

button.botones_target_Mshop span.mshop-tab-span {
  overflow: initial;
  text-overflow: initial;
  white-space: pre-wrap;
  line-height: 18px;
}
