h2.swal2-title {
  background-color: #eee;
  padding-bottom: 15px;
  border-radius: 4px 4px 0 0;
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 10%);
}

div.shadow-table {
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
}


.btn:disabled, .btn.disabled, fieldset:disabled .btn{
  border-color: #c0cfc9 !important;
}