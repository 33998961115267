.margin_fila {
  margin: 20px;
}

.sombra_caja {
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 20%);
}

.caja_input {
  border-radius: 4px;
  border-color: #ccc;
  border: 1px solid #d5d2d2;
  color: #323131;
  padding: 3px;
}

/* button.swal2-confirm {
  background-color: #000 !important;
} */
.swal2-styled.swal2-default-outline:focus {
  box-shadow: none;
}
/* spinner */
.spinner_v {
  display: contents;
  float: left;
}
.spinner_c {
  text-align: center !important;
}

.spinner_opacity {
  opacity: 0.3;
  cursor: not-allowed;
}
