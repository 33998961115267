input {
  font-size: small !important;
  outline: none;
}
input:-internal-autofill-selected {
  background-color: transparent;
}
.form-check-input {
  border-color: #ced4da;
}
.form-control:focus {
  box-shadow: none;
}
.form-control.is-invalid:focus {
  box-shadow: none !important;
}
.form-control.is-invalid::-webkit-input-placeholder {
  color: #db164b;
  font-style: italic;
}
.form-control.is-invalid::-moz-placeholder {
  color: #db164b;
  font-style: italic;
}
.form-control.is-invalid:-ms-input-placeholder {
  color: #db164b;
  font-style: italic;
}
.form-control.is-invalid::-ms-input-placeholder {
  color: #db164b;
  font-style: italic;
}
.form-control.is-invalid::placeholder {
  color: #db164b;
  font-style: italic;
}
label.form-label {
  display: flex;
  text-transform: capitalize;
  font-size: 14px;
  color: #666;
}
.smart-icons {
  outline: none;
}
