.subscription {
  margin-top: 0px;
}

.subscription__wrapper {
  padding: 20px;
  background: #fff;  
  align-items: center;
}

.subscription__wrapper-bor{
  border-right: 1px solid #e1e0e0;
    border-top: 1px solid #e1e0e0;
    border-left: 1px solid #e1e0e0;
    border-radius: 6px 6px 0 0;
    padding: 20px;
  background: #fff;
}

.subscription__wrapper_pass {
  padding: 20px;
  background: #fff;
}

.subscription__wrapper_disable {
  background: #eeeeee;
  color: #bdb8b8;
  padding: 20px;
  margin: 0 !important;
  align-items: center;
  border-bottom: 1px solid #e2dede;
  border-radius: 6px 6px 0 0;
}

.subscription__wrapper_disable_pass {
  background: #eeeeee;
  color: #bdb8b8;
  padding-left: 10px;
}

.subscription__title-container {
  display: flex;
  margin-bottom: 0px;
}

.subscription__title-icon {
  margin-right: 10px;
  font-size: 25px;
  line-height: 20px;
  color: var(--bdw-green);
}

.subscription__title-text {
  font-size: 16px;
    margin: 0;
    cursor: pointer;
    font-weight: 500;
}

.subscription__endpoint-selector-container {
  display: flex;
}
.subscription__buttons-container {
  display: flex;
  justify-content: center;
}

.subscription__endpoint-selector-text,
.vtexskuconsult__text {
  text-align: center;
  vertical-align: middle;
  margin: 10px 2rem 0 0;
  padding: 0 0 0 45px;
}

.subscription-subComponents__container {
  margin-top: 30px;
}

.subscription select,
.vtexskuconsult__input,
.subscription input {
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  height: 35px;
}
.subscription-mshop {
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  height: 35px;
  width: 70%;
}
.vtexskuconsult__textarea {
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  height: 118px;
}

.vtexskuconsult__textarea_56 {
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
  padding: 5px 10px;
  height: 56px;
}

.subscription .inputCheckBox {
  width: 5%;
  margin: 0 0.5rem 0 1rem;
}

.subscription .input {
  width: 50%;
}

.subscription button {
  background: #262324;
  border: none;
  border-radius:3px;
  font-size: 16px;
  color: #fff;
  margin: 0 10px;
  width: 175px;
  height: 40px;
}

.button_container {
  display: flex;
  width: 400px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  left: 14.5%;
}
.ppal_button {
  background: var(--bdw-green) !important;
  border:none;
}
.subscription__preview-button {
  justify-content: flex-end;
  display: flex;
}

.subscription__total-button {
  justify-content: flex-end;
}

.subscription__wrapper h6 {
  font-size: 15px;
  font-weight: 500;
}
.subscription__p {
  font-size: 12px;
  font-weight: 600;
  margin-top: 1rem;
  margin-left: 5rem;
}

input::-webkit-file-upload-button {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  background: var(--bdw-green) !important;
  cursor: pointer;
  color: #fff;
}

.dateInput {
  margin-right: 1rem !important;
}

.inputTotal {
  width: 30%;
}

.inputCheckBox {
  width: 30%;
}

input[type='date']:not(.has-value):before {
  color: var(--bdw-green);
  content: attr(placeholder);
}

#root
  > div:nth-child(5)
  > div
  > div
  > div.automation__wrapper
  > div.row.automation.button
  > div
  > div:nth-child(2)
  > button
  > a {
  color: #fff !important;
  text-decoration: none;
  margin-left: 10px;
}

input.edit-inline-vitrinas{
  width: 22% !important;
}
