.input-disabled{
  background: #fff;
    border-bottom: 1px solid #cbcbcb !important;
    font-size: 14px;
    outline: none;
    padding: 5px 10px;
    height: 35px;
    border: none;
}

.manage-grid-button-audi {
  background: #262324;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  color: #fff;
  margin: 0px;
  width: 120px;
  height: 36px;
}

.jumbotron{
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

.queryBuilder-invalid{
  background-color: #f8d7da;
  border-color: #f5c2c7;

}
.cdp_upload__title-container{
  display: flex;
  margin-bottom: 0;
}
/*
.swal2-styled.swal2-confirm{
  background-color: #dc3545 !important
}
*/

.queryBuilder, .queryBuilder-branches{
	background: #f5f5f5;
    margin: 0 13px;
    border: solid 1px #ddd;
    border-radius: 5px;
    padding: 10px;
}

div[data-testid='rule'] {
  margin: 0px;
    background: #fff;
    padding: 8px 14px;
    border: solid 1px #bab8b8;
    border-radius: 5px;
}

/* .ruleGroup{
  border-color: #d1d0d0 !important;    
  background: #f5f5f5 !important;
} */

.query-builder .ruleGroup {
  padding: 10px;
  padding-bottom: 6px;
  border: 1px solid #9fd4ff;
  background: rgb(210 237 250 / 50%);
}


button[data-testid="remove-rule"], button[data-testid="remove-group"]{
  border: 1px solid #ddd;
    background: #fff;
    color: #a94442;
    border-radius: 3px;
    padding: 0px 8px;
    font-weight: 500;
}

/*button[data-testid="remove-rule"]::before{
    content: "\f014";
    font-family: FontAwesome;
    padding-right:3px;
    color: #a94442;
}*/ 

button[data-testid="add-rule"]{
  background: #2fad7e;
  color: #fff;
  border: 1px solid #b3c3bd;
  border-radius: 5px;
  margin: 0 5px;
  padding: 0 15px;
}

button[data-testid="add-group"]{
  background: #2fad7e;
    color: #fff;
    border: 1px solid #b3c3bd;
    border-radius: 5px;
    margin: 0 5px;
    padding: 0 15px;
}

/*button[data-testid="remove-group"]{
  
}*/

select.ruleGroup-combinators, select.rule-fields, select.rule-operators, select.rule-value{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px 10px;
  margin: 0px 0;
  color: #37393a;
}

select.rule-fields{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px 10px;
  margin: 0px 0;
  color: #666;
}
input[type="number"], input[type="date"]{  
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 2px 10px;
    margin: 0px 0;
    color: #37393a;  
}

.linea-hr{
  margin-left: 13px;
  color: #b3afaf;
  width: 99%;
}

.centrar{
  margin: 0 auto;
}
.botonera {
  margin-bottom: 50px;
}
.shelves {
  margin-top: 20px;
}
.shelves__wrapper {
  padding: 20px;
  background: #fff;
}
.shelves__endpoint-selector-container {
  display: flex;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}

.m-lef {
  margin-left: 5px;
}

.caja-alert{
  height: 42px;
    padding: 9px;
    float: left;
    display: flex;
    margin-top: 10px;
}

.mtr-check{
  margin: 10px 2rem 0 0;
}

.btn-disabled-cdp{
  background-color: var(--bs-btn-disabled-bg);
  font-family: 'Poppins';
}
.lead {
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Poppins';
}
.lead svg {
  font-size: 20px;
  margin: -2px 8px 0 0;
}
div[data-column-id='Year'],
div[data-column-id='Month'],
div[data-column-id='ExistingAudience'],
div[data-column-id='CreatedAudience'],
div[data-column-id='DownloadedAudience'],
div[data-column-id='DownloadedAudience'],
div[data-column-id='TotalInRows'],
div[data-column-id='SavedAudience'] {
  white-space: unset !important;
  text-align: center;
  margin: 0 auto;
}
.sc-dnwKUv.hXLGuA {
    overflow: initial;
}
.header-2 button {
  background: transparent;
}

.hide{
  display: none !important;
}
.show{
  display: block !important;
}

.select-cdp{
  height: 32px;
    width: 80%;
    background: #fcfbfb;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
}

.mt-20{
  margin-top: 20px;
}

.editor-select{
  width: 32rem;
}
