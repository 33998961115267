:root {
  --bdw-green: #00868b;
  --bdw-darkgreen: #067e51;
  --bwd-lightgreen: #7dd9b7;
  --bwd-secondary-yellow: #f29718;
}
.secondary-yellow {
  background-color: #f29718;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 6px 12px;
}
.secondary-yellow:hover,
.secondary-yellow:active {
  background-color: #da8816;
}

html,
body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background: #f4f4f4;
  width: initial;
  /* overflow-y: overlay; */
  color: #2e2e2e;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-menu-dashboard {
  position: relative;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.main-menu-dashboard ul.bdw-menu-wrap li a {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 5px;
  letter-spacing: 0.25px;
}

.floatleft {
  float: left;
}
.floatright {
  float: right;
}
.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}
.alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 15px;
}
.aligncenter {
  display: block;
  margin: 0 auto 15px;
}
a:focus {
  outline: 0px solid;
}
img {
  max-width: 100%;
  height: auto;
}
.fix {
  overflow: hidden;
}
p {
  margin: 0 0 15px;
  font-family: 'Raleway';
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px;
  font-weight: 400;
}
a {
  transition: all 0.3s ease 0s;
  text-decoration: none;
}
a.card-link {
  color: var(--bdw-green);
}
a:hover {
  color: var(--bdw-darkgreen);
  text-decoration: none;
}
a:active,
a:hover,
a:focus {
  outline: 0 none;
  text-decoration: none;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear {
  clear: both;
}
/* body {} */
.pd-tb-25 {
  padding-top: 25px;
}
.pd-tb-15 {
  padding-top: 15px;
}
.mg-tb-40 {
  margin: 40px 0px;
}
.mg-tb-10 {
  margin-top: 10px;
}
.mg-tb-30 {
  margin: 30px 0px;
}
.mg-t-30 {
  margin-top: 30px;
}
.mg-b-15 {
  margin-bottom: 15px;
}
.mg-t-15 {
  margin-top: 15px;
}
.mg-t-10 {
  margin-top: 10px;
}
.mg-t-20 {
  margin-top: 20px;
}
.mg-tb-25 {
  margin-top: 25px;
}
.nk-red {
  background: #f44336;
}
.nk-pink {
  background: #e91e63;
}
.nk-purple {
  background: #9c27b0;
}
.nk-deep-purple {
  background: #673ab7;
}
.nk-indigo {
  background: #3f51b5;
}
.nk-blue {
  background: #2196f3;
}
.nk-light-blue {
  background: #03a9f4;
}
.nk-cyan {
  background: #00bcd4;
}
.nk-teal {
  background: #009688;
}
.nk-green {
  background: #4caf50;
}
.nk-light-green {
  background: #8bc34a;
}
.nk-lime {
  background: #cddc39;
}
.nk-yellow {
  background: #ffeb3b;
}
.nk-amber {
  background: #ffc107;
}
.nk-orange {
  background: #ff9800;
}
.nk-deep-orange {
  background: #ff5722;
}
.nk-brown {
  background: #795548;
}
.nk-gray {
  background: #9e9e9e;
}
.nk-blue-gray {
  background: #607d8b;
}
.nk-black {
  background: #000000;
}

.modal_box button {
  background: #262324;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
  margin: 10px 8px;
  width: 175px;
  height: 40px;
}

.jc-flexend {
  display: flex;
  justify-content: flex-end;
}
.ai-baseline {
  align-items: baseline;
}

.sk-ww-linkedin-page-post.sk-view-sample {
  font-family: 'Poppins' !important;
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins-Regular.ttf') format('truetype'),
    url('fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-Regular.ttf') format('truetype'),
    url('fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
